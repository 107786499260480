<div class="wrapper">
  <div class="sidebar">
    <app-sidebar-cmp></app-sidebar-cmp>
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel d-flex flex-column">
    <app-navbar></app-navbar>
    <div class="full-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
